import { isEmpty } from 'lodash'

const oldConfig = () => {
    switch (process.env.REACT_APP_STAGE) {
        case 'test':
            return {
                showAttention: true,
                showBannerPassport: true,
                servicesDraggable: true,
                sudir: 'login-tech.mos.ru',
                esia: 'esia-portal1.test.gosuslugi.ru',
                service: 'school-test.mos.ru',
                kaluga: 'https://test-school.admoblkaluga.ru',
                tatar: 'https://test-new-edu.tatar.ru',
                region116: 'https://new-edu.tatar.ru',
                region72: 'https://test-pilot.72to.ru',
                region05: 'https://test-pilot.05edu.ru',
                region105: 'https://test-pilot.105edu.ru',
                mo: 'https://test-shkola.mosreg.ru',
                cookieDomain: '.mos.ru',
                esiaClientId: '771402',
                MANUAL_AUTH_LINKING: false,
            }
        case 'prod':
            return {
                showAttention: false,
                showBannerPassport: true,
                servicesDraggable: true,
                sudir: 'login.mos.ru',
                esia: 'esia.gosuslugi.ru',
                service: 'school.mos.ru',
                kaluga: 'https://school.admoblkaluga.ru',
                tatar: 'https://ms-edu.tatar.ru',
                region116: 'https://new-edu.tatar.ru',
                region72: 'https://myschool.72to.ru',
                region05: 'https://pilot.05edu.ru',
                region105: 'https://pilot.105edu.ru',
                mo: 'https://shkola.mosreg.ru',
                cookieDomain: '.mos.ru',
                esiaClientId: '771402',
                MANUAL_AUTH_LINKING: false,
            }
        case 'stg':
            return {
                showAttention: true,
                showBannerPassport: true,
                servicesDraggable: true,
                sudir: 'login-tech.mos.ru',
                esia: 'esia-portal1.test.gosuslugi.ru',
                service: 'school-dev.mos.ru',
                kaluga: 'https://dev-school.admoblkaluga.ru',
                tatar: 'https://dev-new-edu.tatar.ru',
                region116: 'https://new-edu.tatar.ru',
                region72: 'https://dev-pilot.72to.ru',
                mo: 'https://dev-shkola.mosreg.ru',
                cookieDomain: '.mos.ru',
                esiaClientId: '771402',
                MANUAL_AUTH_LINKING: false,
            }
        case 'stg-k8s':
            return {
                showAttention: true,
                showBannerPassport: true,
                servicesDraggable: true,
                sudir: 'login-tech.mos.ru',
                esia: 'esia-portal1.test.gosuslugi.ru',
                service: 'school-dev-k8s.mos.ru',
                kaluga: 'https://dev-school.admoblkaluga.ru',
                tatar: 'https://dev-new-edu.tatar.ru',
                region116: 'https://new-edu.tatar.ru',
                region72: 'https://dev-pilot.72to.ru',
                region05: 'https://dev-pilot.05edu.ru',
                mo: 'https://dev-shkola.mosreg.ru',
                cookieDomain: '.mos.ru',
                esiaClientId: '771402',
                MANUAL_AUTH_LINKING: false,
            }
        case 'stage':
            return {
                showAttention: true,
                showBannerPassport: true,
                servicesDraggable: true,
                sudir: 'login-tech.mos.ru',
                esia: 'esia-portal1.test.gosuslugi.ru',
                kaluga: 'https://dev-school.admoblkaluga.ru',
                tatar: 'https://dev-new-edu.tatar.ru',
                region05: 'https://dev-pilot.05edu.ru',
                region105: 'https://dev-pilot.105edu.ru',
                region116: 'https://new-edu.tatar.ru',
                region72: 'https://dev-pilot.72to.ru',
                service: 'school-stage.mos.ru',
                mo: 'https://dev-shkola.mosreg.ru',
                cookieDomain: '.mos.ru',
                esiaClientId: '771402',
                MANUAL_AUTH_LINKING: false,
            }
        default:
            return {
                regions: {
                    default: {
                        SHOW_TAB_MENU: false,
                        SHOW_ATTENTION: false,
                        SHOW_BANNER_PASSPORT: true,
                        SERVICES_DRAGGABLE: true,
                        MANUAL_AUTH_LINKING: true,

                        SUDIR_URL: 'https://login-tech.mos.ru',
                        ESIA_URL: 'https://esia-portal1.test.gosuslugi.ru',
                        SERVICE_URL: 'https://school-dev.mos.ru',

                        SOURCE: '',
                        FOS: 'aupd',

                        COOKIE_DOMAIN: '.mos.ru',
                        ESIA_CLIENT_ID: '771402'
                    },

                    '05': {
                        SHOW_TAB_MENU: false,
                        SHOW_ATTENTION: false,
                        SHOW_BANNER_PASSPORT: true,
                        SERVICES_DRAGGABLE: true,
                        MANUAL_AUTH_LINKING: true,

                        SUDIR_URL: 'https://login-tech.mos.ru',
                        ESIA_URL: 'https://esia-portal1.test.gosuslugi.ru',
                        SERVICE_URL: 'https://dev-pilot.05edu.ru',

                        SOURCE: '05',
                        FOS: 'aupd_05',

                        COOKIE_DOMAIN: '.05edu.ru',
                        ESIA_CLIENT_ID: '771402'
                    },
                    '16': {
                        SHOW_TAB_MENU: false,
                        SHOW_ATTENTION: false,
                        SHOW_BANNER_PASSPORT: true,
                        SERVICES_DRAGGABLE: true,
                        MANUAL_AUTH_LINKING: true,

                        SUDIR_URL: 'https://login-tech.mos.ru',
                        ESIA_URL: 'https://esia-portal1.test.gosuslugi.ru',
                        SERVICE_URL: 'https://dev-new-edu.tatar.ru',

                        SOURCE: '16',
                        FOS: 'aupd_16',

                        COOKIE_DOMAIN: '.tatar.ru',
                        ESIA_CLIENT_ID: '771402'
                    },
                    '40': {
                        SHOW_TAB_MENU: false,
                        SHOW_ATTENTION: false,
                        SHOW_BANNER_PASSPORT: true,
                        SERVICES_DRAGGABLE: true,
                        MANUAL_AUTH_LINKING: true,

                        SUDIR_URL: 'https://login-tech.mos.ru',
                        ESIA_URL: 'https://esia-portal1.test.gosuslugi.ru',
                        SERVICE_URL: 'https://dev-school.admoblkaluga.ru',
                        COOKIE_DOMAIN: '.admoblkaluga.ru',

                        SOURCE: '40',
                        FOS: 'aupd_40',
                        ESIA_CLIENT_ID: '771402'
                    },
                    '50': {
                        SHOW_TAB_MENU: false,
                        SHOW_ATTENTION: false,
                        SHOW_BANNER_PASSPORT: true,
                        SERVICES_DRAGGABLE: true,
                        MANUAL_AUTH_LINKING: true,

                        SUDIR_URL: 'https://login-tech.mos.ru',
                        ESIA_URL: 'https://esia-portal1.test.gosuslugi.ru',
                        SERVICE_URL: 'https://dev-shkola.mosreg.ru',
                        COOKIE_DOMAIN: '.mosreg.ru',

                        SOURCE: '50',
                        FOS: 'aupd_50',
                        ESIA_CLIENT_ID: '771402'
                    },
                    '72': {
                        SHOW_TAB_MENU: false,
                        SHOW_ATTENTION: false,
                        SHOW_BANNER_PASSPORT: true,
                        SERVICES_DRAGGABLE: true,
                        MANUAL_AUTH_LINKING: true,

                        SUDIR_URL: 'https://login-tech.mos.ru',
                        ESIA_URL: 'https://esia-portal1.test.gosuslugi.ru',
                        SERVICE_URL: 'https://dev-pilot.72to.ru',

                        SOURCE: '72',
                        FOS: 'aupd_72',

                        COOKIE_DOMAIN: '.72to.ru',
                        ESIA_CLIENT_ID: '771402'
                    },
                    '105': {
                        SHOW_TAB_MENU: false,
                        SHOW_ATTENTION: false,
                        SHOW_BANNER_PASSPORT: true,
                        SERVICES_DRAGGABLE: true,
                        MANUAL_AUTH_LINKING: true,

                        SUDIR_URL: 'https://login-tech.mos.ru',
                        ESIA_URL: 'https://esia-portal1.test.gosuslugi.ru',
                        SERVICE_URL: 'https://dev-pilot.105edu.ru',

                        SOURCE: '105',
                        FOS: 'aupd_105',

                        COOKIE_DOMAIN: '.105edu.ru',
                        ESIA_CLIENT_ID: '771402'
                    },
                    '116': {
                        SHOW_TAB_MENU: false,
                        SHOW_ATTENTION: false,
                        SHOW_BANNER_PASSPORT: true,
                        SERVICES_DRAGGABLE: true,
                        MANUAL_AUTH_LINKING: true,

                        SUDIR_URL: 'https://login-tech.mos.ru',
                        ESIA_URL: 'https://esia-portal1.test.gosuslugi.ru',
                        SERVICE_URL: 'https://new-edu.tatar.ru',

                        SOURCE: '116',
                        FOS: 'aupd_116',

                        COOKIE_DOMAIN: '.tatar.ru',
                        ESIA_CLIENT_ID: '771402'
                    }
                }
            }

    }
}

export const getConfig = () => {
    // return oldConfig();
    const aupd_config = localStorage.getItem('aupd_config');
    if (isEmpty(aupd_config)) {
        return oldConfig();
    } else {
        try {
            const config = JSON.parse(aupd_config)
            return config
        }catch (e) {
            return  oldConfig()
        }
    }
}

export default () => {
  try {
    // eslint-disable-next-line global-require
    const gitInfo = require('.gitinfo.json')
    if (!gitInfo) {
      throw gitInfo
    }
    if (process.env.REACT_APP_ENV !== 'prod') {
      return gitInfo
    }
    return gitInfo?.currentTag
  } catch (error) {
    console.warn('Не удалось получить информацию о сборке', error)
  }
}

import { request } from 'API/request';
import { alert } from './alertify';
import { regionConfigValue } from "./regionConfigValue";

export const generateFosLink = async () => {
    const link = await request({
        url: `/v1/urlSign?url=https://school.mos.ru/fos/aupd?timestamp=${new Date().getTime()}`,
        method: 'GET',
    });
    window.location = link
}

export const generateFosEsia = async () => {
    const { url } = await request({
        url: `/fos/v1/url/?product=aupd`,
        method: 'GET',
        headers: { 'x-mes-subsystem': 'footerweb' }
    });
    if (url) {
        window.location = url
    }
}

export const fosApi = async () => {
    const { FOS } = regionConfigValue();

    const { url } = await request({
        url: `/api/fos/v1/url/?product=${FOS}`,
        method: 'GET',
        headers: { 'x-mes-subsystem': 'footerweb' }
    });
    if (url) {
        window.location = url
    } else {
        alert({ mode: 'error', message: 'Что-то пошло не так' });
    }
}

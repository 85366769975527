import {useContext, useState, useEffect} from "react";
import { isEmpty } from 'lodash';
import {GlobalContext} from "../context";
import {MeshIcon, EsiaLogo} from "../components/FlowImages";
import parseTokens from "utils/parseToken";

export const THEME_TYPE = {
  MESH: 'mesh',
  ESIA: 'esia'
}

const ContextWorker = () => {
  const {context, setContext} = useContext(GlobalContext);
  const [globalTheme, setGlobalTheme] = useState(THEME_TYPE.MESH)
  const [globalLogo, setGlobalLogo] = useState(THEME_TYPE.MESH)
  const [globalName, setGlobalName] = useState("Московская электронная школа")

  useEffect(() => {
    if (globalTheme === THEME_TYPE.ESIA) {
      setGlobalLogo(EsiaLogo)
    }else {
      setGlobalLogo(MeshIcon)
    }
  }, [globalLogo, globalTheme, globalName])

  useEffect(() => {
    if (globalTheme === THEME_TYPE.ESIA) {
      setGlobalName("Моя школа")
    }else {
      setGlobalName("Московская электронная школа")
    }
  }, [globalLogo, globalTheme, globalName])

  useEffect(() => {
    if (globalTheme === THEME_TYPE.ESIA) {
      document.body.classList.add('esia-body');
    }else {
      document.body.classList.remove('esia-body');
    }
  }, [globalTheme])

  useEffect(() => {
    const parseToken = parseTokens();
    if (!isEmpty(parseToken)) {
      if (isEmpty(parseToken.rgn) || parseToken.rgn === "77") {
        setGlobalTheme(THEME_TYPE.MESH)
      }else {
        setGlobalTheme(THEME_TYPE.ESIA)
      }
    }

  }, [setGlobalTheme])


  useEffect(() => {
    if (context.globalTheme !== globalTheme || context.globalLogo !== globalLogo || context.globalName !== globalName) {
      setContext({
        ...context,
        globalTheme,
        globalLogo,
        globalName,
      })
    }
  }, [context, globalTheme, setContext, globalLogo, globalName])
}

export default ContextWorker

export const TABLE_GET_REQUEST = 'TABLE_GET_REQUEST';
export const TABLE_GET_SUCCESS = 'TABLE_GET_SUCCESS';
export const TABLE_GET_FAILURE = 'TABLE_GET_FAILURE';
export const GET_USERS_DATA = 'GET_USERS_DATA';
export const GET_ROLES_DATA = 'GET_ROLES_DATA';
export const GET_ROLES_SUCCESS = 'GET_ROLES_SUCCESS';

export const USER_INFO_REQUEST = 'USER_INFO_REQUEST';

export const AUTH_REGIONS = 'AUTH_REGIONS';
export const AUTH_REGIONS_SUCCESS = 'AUTH_REGIONS_SUCCESS';

export const SET_USER_DATA = 'SET_USER_DATA'

export const GET_DEVICES_REQUEST = 'GET_DEVICES_REQUEST'
export const GET_DEVICES_SUCCESS = 'GET_DEVICES_SUCCESS'

export const DELETE_DEVICES_REQUEST = 'DELETE_DEVICES_REQUEST'

export const ADD_DEVICE_REQUEST = 'ADD_DEVICE_REQUEST'
export const EDIT_DEVICE_REQUEST = 'EDIT_DEVICE_REQUEST'
export const REGISTER_CHILDREN_REQUEST = 'REGISTER_CHILDREN_REQUEST'
export const LOGIN_REQUEST = 'LOGIN_REQUEST'
export const GET_LOGIN_SUCCESS = 'GET_LOGIN_SUCCESS'
export const CHANGE_PASSWORD_CHILDREN_REQUEST = 'CHANGE_PASSWORD_CHILDREN_REQUEST'

export const DEACTIVATE_NOTIFICATION = 'DEACTIVATE_NOTIFICATION'

export const GET_CHILDREN_REQUES = 'GET_CHILDREN_REQUES'
export const GET_CHILDREN_SUCCESS = 'GET_CHILDREN_SUCCESS'

export const GET_CONTACTS_USER_REQUEST = 'GET_CONTACTS_USER_REQUEST'
export const GET_CONTACTS_USER_SUCCESS = 'GET_CONTACTS_USER_SUCCESS'
export const GET_PARENT_SUCCESS = 'GET_PARENT_SUCCESS'

export const GET_PIN_REQUEST = 'GET_PIN_REQUEST'
export const GET_PIN_SUCCESS = 'GET_PIN_SUCCESS'


export const UPDATE_PIN_REQUEST = 'UPDATE_PIN_REQUEST'
export const UPDATE_PIN_SUCCESS = 'UPDATE_PIN_SUCCESS'

export const REGION_CODE_05 = '05';
export const REGION_CODE_16 = '16';
export const REGION_CODE_40 = '40';
export const REGION_CODE_50 = '50';
export const REGION_CODE_72 = '72';
export const REGION_CODE_105 = '105';
export const REGION_CODE_116 = '116';

export const LOGIN_TYPE_ESIA = 'esia';
export const LOGIN_TYPE_LOCAL = 'local';

import { GET_CONTACTS_USER_REQUEST, GET_CONTACTS_USER_SUCCESS } from 'constants/index';

export const getContactsRequest = (payload) => {
    return ({
        type: GET_CONTACTS_USER_REQUEST, payload
    })
}

export const getContactsSuccess = (payload) => ({
    type: GET_CONTACTS_USER_SUCCESS, payload
});
import React, { useContext } from 'react'
import { useLocation } from 'react-router-dom'
import { YMInitializer } from 'react-yandex-metrika'
import ContextWorker, { THEME_TYPE } from '../../contextWorker'
import { GlobalContext } from '../../context'
import { get } from 'lodash'

const options = {
    clickmap:true,
    trackLinks:true,
    accurateTrackBounce:true,
    webvisor:false
}
const YaMetrika = () => {
  ContextWorker()
  const { context } = useContext(GlobalContext)
  const globalTheme = get(context, 'globalTheme')
  const location = useLocation()
  if(location.pathname === '/esia') {
    return (
      <YMInitializer accounts={[90856974]} options={options} version="2" />
    )
  }
  if(location.pathname === '/') {
    return (
      <YMInitializer accounts={[71568595]} options={options} version="2" />
    )
  }
  const account = globalTheme === THEME_TYPE.ESIA ? 90856974 : 71568595
  return (
    <YMInitializer accounts={[account]} options={options} version="2" />
  )
}

export default YaMetrika

import { createMuiTheme } from '@material-ui/core'

export default createMuiTheme({
    overrides: {
        MuiIconButton: {
            root: {
                outline: 'none !important'
            },
        },
    },
});
import {GET_PIN_SUCCESS, GET_PIN_REQUEST, UPDATE_PIN_REQUEST, UPDATE_PIN_SUCCESS} from 'constants/index'

export default (state = { loading: false, data: undefined  }, action) => {
    switch(action.type){
        case GET_PIN_SUCCESS:
        case UPDATE_PIN_SUCCESS:
            return { loading: false, data: action.payload };

        case GET_PIN_REQUEST:
        case UPDATE_PIN_REQUEST:
            return { loading: true, data: state.data };

        default:
            return state;
    }
}

import IntroIcon from 'assets/img/images/intro.png'
import IntroImg from 'assets/img/images/intro.svg'
import IntroImgPng from 'assets/img/images/intro_png.png'
import IntroImgPngMob from 'assets/img/images/intro_mob_png.png'
import IntroImgPngHappy from 'assets/img/images/introHappy2023.png'
import IntroImgPngMobHappy from 'assets/img/images/intro_mob_png_happy.gif'
import IntroPatentsPngHappy from 'assets/img/images/introParentsHappy2023.png'
import IntroPatentsPng from 'assets/img/images/intro-parents_png.png'

import IntroPatentsSepPng from 'assets/img/images/intro-parents-sep.png'
import IntroPatentsPngHappySep from 'assets/img/images/intro_png_happy-sep.png'

import IntroImgMob from 'assets/img/images/intro-mob.svg'
import IntroParents from 'assets/img/images/intro-parents.svg'
import IntroIconMobile1 from 'assets/img/images/intro-mobile1.svg'
import IntroIconMobile2 from 'assets/img/images/intro-mobile2.svg'
import MeshIcon from 'assets/img/logo.svg'
import CallBack from 'assets/img/images/callback-min.png'
import MosLogo from 'assets/img/mos-logo.svg'
import ActivationError from 'assets/img/images/close-circle.svg'
import ActivationSuccess from 'assets/img/images/checkmark-circle.svg'

import ParallaxOval from 'assets/img/parallax-elements/oval.svg'
import ParallaxCircle from 'assets/img/parallax-elements/circle.svg'
import ParallaxLine from 'assets/img/parallax-elements/line.svg'
import ParallaxLineSmall from 'assets/img/parallax-elements/line-small.svg'
import ParallaxEgg from 'assets/img/parallax-elements/egg.svg'
import ParallaxTriangle from 'assets/img/parallax-elements/triangle.svg'
import ParallaxTriangleBig from 'assets/img/parallax-elements/triangle-big.svg'
import ParallaxRoundTriangleBlue from 'assets/img/parallax-elements/round-triangle-blue.svg'
import ParallaxRoundTriangleOrange from 'assets/img/parallax-elements/round-triangle-orange.svg'
import ParallaxRoundTriangleSmall from 'assets/img/parallax-elements/round-triangle-small.svg'
import ParallaxRoundTriangleViolet from 'assets/img/parallax-elements/round-triangle-violet.svg'
import ParallaxFour from 'assets/img/parallax-elements/4.svg'
import ParallaxZero from 'assets/img/parallax-elements/0.svg'
import WifiMan from 'assets/img/wifi/man.svg'
import WifiWoman from 'assets/img/wifi/woman.svg'
import WifiClouds from 'assets/img/wifi/clouds.svg'
import WifiCheckmark from 'assets/img/wifi/checkmark.svg'
import WifiClose from 'assets/img/wifi/close.svg'

import ErrorImage from 'assets/img/images/error.jpg'

import EngineeringWorks from 'assets/img/images/engineering-works.png'

import ParralaxHappy1 from 'assets/img/parallax-heppy/Happy1.svg'
import ParralaxHappy2 from 'assets/img/parallax-heppy/Happy2.svg'
import ParralaxHappy3 from 'assets/img/parallax-heppy/Happy3.svg'
import ParralaxHappy4 from 'assets/img/parallax-heppy/Happy4.svg'

import ParralaxHappySep1 from 'assets/img/parallax-september/happy1.svg'
import ParralaxHappySep2 from 'assets/img/parallax-september/happy2.svg'
import ParralaxHappySep3 from 'assets/img/parallax-september/happy3.svg'
import ParralaxHappySep4 from 'assets/img/parallax-september/happy4.svg'
import ParralaxHappySep5 from 'assets/img/parallax-september/happy5.svg'
import ParralaxHappySep6 from 'assets/img/parallax-september/happy6.svg'
import ParralaxHappySep7 from 'assets/img/parallax-september/happy7.svg'
import ParralaxHappySep8 from 'assets/img/parallax-september/happy8.svg'
import ParralaxHappySep9 from 'assets/img/parallax-september/happy9.svg'


import IconMos from 'assets/img/icons/icon-mos.svg'
import IconSotrudnic from 'assets/img/icons/Icon-sotrudnic.svg'
import IconStudent from 'assets/img/icons/student.svg'
import EsiaLogo from 'assets/img/esia-logo.svg'

export {
    IntroImgPngHappy, IntroImgPngMobHappy, IntroPatentsPngHappy, IntroPatentsSepPng, IntroPatentsPngHappySep,

    ParralaxHappy1, ParralaxHappy2, ParralaxHappy3, ParralaxHappy4,

    ParralaxHappySep1,
    ParralaxHappySep2,
    ParralaxHappySep3,
    ParralaxHappySep4,
    ParralaxHappySep5,
    ParralaxHappySep6,
    ParralaxHappySep7,
    ParralaxHappySep8,
    ParralaxHappySep9,


    EsiaLogo,
    IntroImg, IntroIcon, IntroIconMobile1, IntroIconMobile2, MeshIcon, ParallaxOval, ParallaxCircle,  ParallaxEgg, ParallaxTriangle, ParallaxRoundTriangleBlue,
    ParallaxRoundTriangleOrange, ParallaxRoundTriangleSmall,ParallaxRoundTriangleViolet, ErrorImage, ParallaxTriangleBig,
    ParallaxLine, ParallaxFour, ParallaxZero, ParallaxLineSmall, EngineeringWorks, CallBack, IntroParents, IntroImgMob, IntroImgPng, IntroImgPngMob, IntroPatentsPng,
    MosLogo, ActivationError, ActivationSuccess, WifiMan, WifiWoman, WifiClouds, WifiCheckmark, WifiClose, IconMos, IconSotrudnic, IconStudent
}

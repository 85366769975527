import {
    GET_CHILDREN_SUCCESS,
    GET_CHILDREN_REQUES,
    REGISTER_CHILDREN_REQUEST,
    LOGIN_REQUEST,
    GET_LOGIN_SUCCESS,
    CHANGE_PASSWORD_CHILDREN_REQUEST, GET_PARENT_SUCCESS
} from 'constants/index';

export const getChildrenRequest = (payload) => {
    return ({
        type: GET_CHILDREN_REQUES, payload
    })
}

export const getChildrenSuccess = (payload) => ({
    type: GET_CHILDREN_SUCCESS, payload
});

export const registerChildren = (payload) => ({
    type: REGISTER_CHILDREN_REQUEST, payload
})

export const getLogin = (payload) => ({
    type: LOGIN_REQUEST, payload
})

export const getLoginSuccess = (payload) => ({
    type: GET_LOGIN_SUCCESS, payload
})

export const changePasswordRequest = (payload) => ({
    type: CHANGE_PASSWORD_CHILDREN_REQUEST, payload
})

export const getParentSuccess = (payload) => ({
    type: GET_PARENT_SUCCESS, payload
})
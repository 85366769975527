import parseTokens from "./parseToken";
import { isEmpty } from "lodash";
import { getConfig } from "./contur";
import {
    REGION_CODE_05,
    REGION_CODE_105,
    REGION_CODE_116,
    REGION_CODE_16,
    REGION_CODE_40,
    REGION_CODE_50,
    REGION_CODE_72
} from "../constants";
import {useMemo} from "react";

const configEmpty = {
    SHOW_ATTENTION: false,
    SHOW_BANNER_PASSPORT: false,
    SERVICES_DRAGGABLE: false,
    MANUAL_AUTH_LINKING: false,
    SUDIR_URL: '',
    ESIA_URL: '',
    SERVICE_URL: '',
    SOURCE: '',
    FOS: '',
    COOKIE_DOMAIN: '',
    ESIA_CLIENT_ID: '',
}

const getIncludes = (key, value) => {
    return window.location[key].includes(value);
}

export const regionConfigValue = () => {
    const config = getConfig();
    const parseToken = parseTokens();
    const { pathname } = window.location;
    const isDefaultRoute = pathname.split('/')?.[1] === 'esia';

    let regionKey = 'default';

    if (!isEmpty(parseToken)) {
        regionKey = (parseToken?.rgn && (parseToken?.rgn !== '77')) ? parseToken?.rgn : 'default';
    } else if (isDefaultRoute) {
        const regionId = pathname.split('/')?.[2];
        if (regionId) {
            regionKey = regionId
        }
    } else {
        const isSchool = getIncludes('origin', 'mos.') || window.location.hostname === 'localhost';

        if (getIncludes('origin', 'mosreg') || (isSchool && getIncludes('pathname', `/${REGION_CODE_50}`))) {
            regionKey = REGION_CODE_50;
        }
        if (getIncludes('origin', 'admoblkaluga') || (isSchool && getIncludes('pathname', `/${REGION_CODE_40}`))) {
            regionKey = REGION_CODE_40;
        }
        if (getIncludes('origin', REGION_CODE_16) || (isSchool && getIncludes('pathname', `/${REGION_CODE_16}`))) {
            regionKey = REGION_CODE_16;
        }
        if (getIncludes('origin', REGION_CODE_05) || (isSchool && getIncludes('pathname', `/${REGION_CODE_05}`))) {
            regionKey = REGION_CODE_05;
        }
        if (getIncludes('origin', REGION_CODE_72) || (isSchool && getIncludes('pathname', `/${REGION_CODE_72}`))) {
            regionKey = REGION_CODE_72;
        }
        if (getIncludes('origin', REGION_CODE_105) || (isSchool && getIncludes('pathname', `/${REGION_CODE_105}`))) {
            regionKey = REGION_CODE_105;
        }
        if (getIncludes('origin', REGION_CODE_116) || (isSchool && getIncludes('pathname', `/${REGION_CODE_116}`))) {
            regionKey = REGION_CODE_116;
        }
    }

    if (config?.regions?.hasOwnProperty(regionKey)) {
        return config?.regions[regionKey];
    } else {
        return config?.regions?.default;
    }
}

import React from 'react'
import classes from 'assets/sass/style.module.scss';
import {MeshIcon} from 'components/FlowImages';

function HeaderComponent() {
    return (
        <header className={classes['site_header']}>
            <div className={classes['main-container']}>
                <div className={classes['site_header__inner']}>
                    <div className={classes['logo']}>
                        <img className={classes['logo_img']} src={MeshIcon} alt="logo"/>
                    </div>
                </div>
            </div>
        </header>
    )
}

export default HeaderComponent
import React from 'react';
import { render } from 'react-snapshot';
import { Provider } from 'react-redux';
import { MuiThemeProvider } from '@material-ui/core';
import { ConnectedRouter } from 'connected-react-router'
import { PersistGate } from 'redux-persist/integration/react';
import GlobalProvider from './context'
import theme  from 'utils/theme'
import App from 'App';
import buildInfo from 'utils/buildInfo'

import { store, persistor, history } from './configureStore';

console.info(`Версия сервиса: `, buildInfo())

const app = document.getElementById('root');

const renderApp = () => {
	render(
		<Provider store={store}>
			<PersistGate loading={null} persistor={persistor}>
				<ConnectedRouter history={history}>
						<MuiThemeProvider theme={theme}>
							<GlobalProvider>
								<App />
							</GlobalProvider>
						</MuiThemeProvider>
				</ConnectedRouter>
			</PersistGate>
		</Provider>,
		app,
	);
};
renderApp();

import { createStore, applyMiddleware, compose } from 'redux';
import { createBrowserHistory } from 'history';

import createSagaMiddleware from 'redux-saga';
import { persistStore } from 'redux-persist';

import createReducer from './reducers';
import mySaga from './sagas'

import API from './API';

export const history = createBrowserHistory({});

export default function configureStore(initialState = {}, history) {
  const sagaMiddleware = createSagaMiddleware()

  const middlewares = [
    API.middleware,
    sagaMiddleware,
  ];

  const enhancers = [
    applyMiddleware(...middlewares),
  ];

  const composeEnhancers = typeof window === 'object'
  && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        shouldHotReload: false,
      })
      : compose;

  const store = createStore(
      createReducer(history),
      initialState,
      composeEnhancers(...enhancers),
  );


  store.runSaga = sagaMiddleware.run(mySaga);
  store.injectedReducers = {};
  store.injectedSagas = {};

  if (module.hot) {
    module.hot.accept('./reducers', () => {
      store.replaceReducer(createReducer(store.injectedReducers));
    });
  }

  const persistor = persistStore(store);

  return { store, persistor };
}

const initialState = {};
export const { store, persistor } = configureStore(initialState, history);

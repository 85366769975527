import {AUTH_REGIONS_SUCCESS, GET_ROLES_SUCCESS, SET_USER_DATA} from 'constants/index'

export default (state = {}, action) => {
    switch(action.type){
        case GET_ROLES_SUCCESS:
            return action.payload;

        case AUTH_REGIONS_SUCCESS:
            return action.payload;

        case SET_USER_DATA:
            return action.payload;

        default:
            return state;
    }
}

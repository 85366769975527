import axios from 'axios';

import {getCookie} from "../utils/cookie";
import {alert} from "../utils/alertify";

export function makeRequest(options, returnStatus) {
    return axios(options)
        .then(response => {
            if (response.status < 200 && response.status > 300 && response.status !== 404) throw response;
            if (options.url.includes('/v1/sudir/main')) {
                return response?.data
            }

            if (options.url.includes('/v3/auth/rights/')) {
                return response?.data
            }
            if (response?.data?.error) throw response;
            if (options.url === '/v2/redirect') {
                return response?.request?.responseURL
            }
            if (returnStatus) {
                return {
                    data: response.data,
                    status: response.status
                }
            }
            return response.data;
        })
        .catch(error => {
            if (options.url === '/v2/external-partners/generate-token' || options.url.includes('/v2/external-partners/account-linking/')) {
                throw error?.response
                return
            }

            if (error?.response?.data) {
                throw error?.response?.data
                return
            }
            if( error.response ){
                alert({mode: 'error', message: error?.response?.data?.[0].errorMessage || error?.response?.data?.error})
            }
            throw error
        });
}


export function request({ method, url, data, params, token, headers }, returnStatus = false) {
    // url = `http://localhost:5001${url}`
    const options = { method, url };
    options.headers = {
        Authorization: `Bearer ${getCookie('aupd_token')}`,
        ...headers
    };
    if (data) options.data = data;
    if (params) options.params = params;
    options.withCredentials = true
    return makeRequest(options, returnStatus);
}

import {
    GET_PIN_REQUEST, GET_PIN_SUCCESS, UPDATE_PIN_SUCCESS, UPDATE_PIN_REQUEST
} from 'constants/index';

export const getPinRequest = (payload) => ({
    type: GET_PIN_REQUEST, payload
})

export const getPinSuccess = (payload) => ({
    type: GET_PIN_SUCCESS, payload
})

export const updatePinRequest = (payload) => ({
    type: UPDATE_PIN_REQUEST, payload
})

export const updatePinSuccess = (payload) => ({
    type: UPDATE_PIN_SUCCESS, payload
})

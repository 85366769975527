import React, {useCallback} from 'react'

// Style
import classes from "./assets/scss/style.module.scss";

// Img
import Logo from "./assets/img/logo.svg"
import NotFoundImg from "./assets/img/404.png"

import { fosApi } from 'utils/generateFos'
import parseTokens from "../../utils/parseToken";
import { isEmpty } from 'lodash';

/**
 * Переход на хост
 */
const goToHost = (serviceUrl) => {
  window.location = serviceUrl || `${window.location.origin}`;
}

export const RegionNotFound = (props) => {

  const parseToken = parseTokens();

  /**
   * Переход на главную
   * @type {function(): void}
   */
  const handleGoToAuth = useCallback(() => {

    /**
     * Если есть токен
     */
    if (!isEmpty(parseToken)) {

      const isSchool = window.location.origin.includes('mos.') || window.location.hostname === 'localhost';

      /**
       * Если пользователь на домене school
       */
      if (isSchool) {
        if (parseToken?.rgn) {
          if (props.config?.SERVICE_URL) {
            window.location = `${props.config?.SERVICE_URL}/${parseToken?.rgn}/`;
          } else {
            window.location = `${window.location.origin}/${parseToken?.rgn}/`
          }
        } else {
          goToHost(props.config?.SERVICE_URL);
        }
      } else {
        goToHost(props.config?.SERVICE_URL);
      }

    } else {
      goToHost(props.config?.SERVICE_URL);
    }
  }, [parseToken]);

  return (
    <div className={classes['site_wrapper']}>
      <main className={classes['site_main']}>
        <section className={classes['sec-not-found']}>
          <div className={classes['container']}>
            <div className={classes['sec-not-found_head']}>
              <div className={classes['logo']}>
                <img src={Logo} alt="logo"/>
              </div>
            </div>
            <div className={classes['sec-not-found_content']}>
              <div className={classes['not-found']}>
                <div className={classes['not-found_info']}>
                  <div className={classes['not-found_title']}>
                    Такую страницу пока
                    не изобрели
                  </div>
                  <div className={classes['not-found_description']}>
                    Пожалуйста, обратитесь в <span className={classes['not-found_cursor']} onClick={fosApi}>службу поддержки</span>
                    для решения вопроса.
                  </div>
                  <div className={classes['not-found_action']}>
                    <button onClick={handleGoToAuth} className={classes['btn-arrow']}>
                      Вернуться к авторизации
                    </button>
                  </div>
                </div>
                <div className={classes['not-found_img']}>
                  <img src={NotFoundImg} alt="404"/>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
};

export default RegionNotFound;

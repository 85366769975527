import { put, takeLatest, all, call } from 'redux-saga/effects';
import {
    GET_DEVICES_REQUEST,
    DELETE_DEVICES_REQUEST,
    ADD_DEVICE_REQUEST,
    DEACTIVATE_NOTIFICATION, EDIT_DEVICE_REQUEST
} from 'constants/index';
import { getDevicesSuccess, getDevicesRequest } from 'actions/devices'
import { alert } from 'utils/alertify'
import { deleteCookie, setCookieForever } from 'utils/cookie'
import API from 'API'


function* getDevices() {
    try {
        const response = yield call(API.getDevices);
        yield put(getDevicesSuccess(response));
    }catch (e) {
        console.warn(e)
    }
}

function* deleteDevices({ payload: { id, aupd_device_id } }) {
    try {
        yield call(API.deleteDevice, { id });
        if (id === Number(aupd_device_id)) {
            deleteCookie('aupd_device_id')
        }
        yield put(getDevicesRequest());
        alert({mode: 'success', message: 'Устройство успешно удалено'})
    }catch (e) {
        console.warn(e)
    }
}

function* addDevice({ payload: { roleId, subsystem, deviceAlias } }) {
    try {
        const aupd_device_id = yield call(API.addDevice, { roleId, subsystem, deviceAlias })
        setCookieForever('aupd_device_id', aupd_device_id)

        alert({mode: 'success', message: 'Устройство успешно добавлено.'})
        yield put(getDevicesRequest());
    }catch (e) {
        console.warn(e)
    }
}

function* editDevice({ payload }) {
    try {
        yield call(API.editDevice, payload)
        alert({mode: 'success', message: 'Устройство успешно изменено.'})
        yield put(getDevicesRequest());
    }catch (e) {
        console.warn(e)
    }
}

function* deactivateNotif() {
    try {
        yield call(API.deactivateNotification)
        const user = yield call(API.getUserInfo)
        localStorage.setItem("user_data", JSON.stringify(user))
        yield put(getDevicesRequest());
        window.location.reload();
    }catch (e) {
        console.warn(e)
    }
}

function* Saga() {
    yield all([
        takeLatest(GET_DEVICES_REQUEST, getDevices),
        takeLatest(DELETE_DEVICES_REQUEST, deleteDevices),
        takeLatest(ADD_DEVICE_REQUEST, addDevice),
        takeLatest(EDIT_DEVICE_REQUEST, editDevice),
        takeLatest(DEACTIVATE_NOTIFICATION, deactivateNotif),
    ]);
}

export default Saga;

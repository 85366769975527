import { takeLatest, all, call, put } from 'redux-saga/effects';
import { GET_CHILDREN_REQUES, LOGIN_REQUEST, REGISTER_CHILDREN_REQUEST, CHANGE_PASSWORD_CHILDREN_REQUEST } from 'constants/index';
import {getChildrenRequest, getChildrenSuccess, getLoginSuccess, getParentSuccess} from 'actions/childrens'
import API from 'API'
import { alert} from "utils/alertify";
import { closeDialog } from "components/Flows/Dialog/actions";
import errorsNormalize from '../../utils/errorsNormalize'
import {handleLogout} from "../../utils/function";

function* getChildren({ payload }) {
    try {
        const { entities = [], parentCategories } = yield call(API.getChildren, { ...payload });
        yield put(getParentSuccess(parentCategories))
        // const newEntities = entities.filter(({ education }) => !isEmpty(education) )
        yield put(getChildrenSuccess(entities));
    }catch (e) {
        console.log(e)
    }
}

function* registerChildren({ payload: { mesh_id, ...args } }) {
    try {
        yield call(API.registerChildren, args, mesh_id);
        yield put(getChildrenRequest({sso_id: args.parentId}));
        alert({mode: 'success', message: 'Успешное создание учетной записи'})
        yield put(closeDialog({ id: `children:dialog:${args.last}_${args.first}_${args.middle}`}))
    }catch (e) {
        alert({mode: 'error', message: errorsNormalize(e)})
        yield put(closeDialog({ id: `children:dialog:${args.last}_${args.first}_${args.middle}`}))
    }
}

function* getLogin({ payload: { mesh_id, ...args } }) {
    try {
        const login = yield call(API.getLogin, args, mesh_id);
        yield put(getLoginSuccess(login))
    }catch (e) {
        alert({mode: 'error', message: 'Ошибка получения логина'})
    }
}

function* changePassword({ payload: { ssoId, ...args } }) {
    try {
        const responseChangePWD = yield call(API.changePassword, args, ssoId);
        if (!responseChangePWD.result) throw { errors: responseChangePWD?.errors }
        yield put(getChildrenRequest({sso_id: args.parentId}));
        alert({mode: 'success', message: 'Успешное изменение учетной записи'})
        yield put(closeDialog({ id: `children:password:${args.LastName}_${args.FirstName}_${args.MiddleName}`}))
    }catch (e) {
        const isE0003 = e?.error === 'E0003';
        alert({mode: 'error', message: isE0003 ? 'Сессия истекла, пожалуйста, авторизуйтесь заново' : errorsNormalize(e)})
        yield put(closeDialog({ id: `children:password:${args.LastName}_${args.FirstName}_${args.MiddleName}`}));

        if (e?.error === 'E0003') {
            handleLogout(true);
        }
    }
}

function* Saga() {
    yield all([
        takeLatest(GET_CHILDREN_REQUES, getChildren),
        takeLatest(REGISTER_CHILDREN_REQUEST, registerChildren),
        takeLatest(LOGIN_REQUEST, getLogin),
        takeLatest(CHANGE_PASSWORD_CHILDREN_REQUEST, changePassword)
    ]);
}

export default Saga;

import { isEmpty } from 'lodash'

const errorsNormalize = (errorBody) => {
  const { brief, errors } = errorBody
  if (brief) {
    if (brief.includes('exceptions.DataProcessingException: Ошибка регистрации учетной записи: Такой пользователь уже зарегистрирован. Введите другое значение или воспользуйтесь функцией')){
      return `Такой пользователь уже зарегистрирован. Введите другое значение или воспользуйтесь функцией  «Восстановить пароль»`
    }
    if(brief.includes('exceptions.DataProcessingException: Ошибка обновления токена, возможно он устарел')){
      return `Ошибка обновления токена, возможно он устарел`
    }
    if (brief.includes('exceptions.DataProcessingException: Ошибка регистрации учетной записи: Пароль не соответствует парольным политикам: длина менее 8 символов.')) {
      return `Пароль не соответствует достаточному количеству групп символов`
    }
    if (brief.includes('exceptions.DataProcessingException: Ошибка регистрации учетной записи: Пароль не соответствует парольным политикам: содержится в словаре известных паролей.')) {
      return `Пароль не соответствует парольным политикам: не содержит строчную букву или прописную букву`
    }
    if (brief.includes('exceptions.DataProcessingException: Ошибка регистрации учетной записи: err.reg.api.user_exist.mobile')) {
      return `Пользователь с указанными данными уже имеет учетную запись. Воспользуйтесь формой «Восстановить пароль»`
    }
  }
  if (!isEmpty(errors) && errors?.[0].desc) {
    switch (errors?.[0].desc) {
      case 'содержится в словаре известных паролей':
        return 'Пароль не соответствует парольным политикам: содержится в словаре известных паролей'
      case 'убедитесь, что пароль соответствует всем требованиям и не использовался ранее':
        return 'Пароль не соответствует парольным политикам: убедитесь, что пароль  не использовался ранее'
      default:
        return errors?.[0].desc
    }
  }
  return 'Неизвестная ошибка'
}

export default errorsNormalize

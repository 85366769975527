import { all, fork } from 'redux-saga/effects';

import pin from 'sagas/pin';
import table from 'sagas/table';
import auth from 'sagas/auth';
import device from 'sagas/device';
import children from 'sagas/children'
import contacts from 'sagas/contacts'
function* Saga() {
    yield all([
        fork(table),
        fork(pin),
        fork(auth),
        fork(device),
        fork(children),
        fork(contacts)
    ]);
}

export default Saga;

/**
 * Combine all reducers in this file and export the combined reducers.
 */
import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router'

import { persistReducer } from 'redux-persist';
import immutableTransform from 'redux-persist-transform-immutable';
import storage from 'localforage';

import dialogReducer from 'components/Flows/Dialog/reducer'
import table from "./table";
import user from "./user";
import pin from "./pin";
import device from "./device";
import children from "./children";
import contacts from "./contacts";
import modal from "./modal";
import parentCategories from "./parentCategories";

const rootPersistConfig = {
    transforms: [immutableTransform()],
    key: 'MESHRoot_1',
    whitelist: ['auth', 'global'],
    storage,
};

/**
 * Creates the main reducer with the dynamically injected ones
 */
export default function createReducer(history) {
    return persistReducer(rootPersistConfig,
        combineReducers({
            router: connectRouter(history),
            table,
            user,
            device,
            children,
            contacts,
            modal,
            pin,
            parentCategories,

            //component
            dialog: dialogReducer,
        }));
}

import { put, takeLatest, all, call } from 'redux-saga/effects';
import { AUTH_REGIONS } from 'constants/index';
import { authRegionsSuccess } from 'actions/auth'
import API from 'API'

function* authRegionFunction({ payload: { state, history } }) {
    try {
        const response = yield call(API.authRegion, { data: state });
        history.push('/choice')
        yield put(authRegionsSuccess(response));
    }catch (e) {
        console.log(e)
    }
}

function* Saga() {
    yield all([
        takeLatest(AUTH_REGIONS, authRegionFunction),
    ]);
}

export default Saga;
import React, { useState, memo } from 'react'
import {regionConfigValue} from "../utils/regionConfigValue";
import YaMetrika from "../components/YAMetrika";

export const GlobalContext = React.createContext({})
const GlobalProvider = memo(({ children }) => {
    const config = regionConfigValue();
    const initialState = {}
    const [context, setContext] = useState(initialState)

    return (
        <GlobalContext.Provider value={{context, setContext}}>
            {children}
            {(config?.SERVICE_URL === 'https://school.mos.ru') && <YaMetrika />}
        </GlobalContext.Provider>
    )
})

export default GlobalProvider

import alertify from 'alertify.js'
export const alert = ({ mode, message, delay = 5000, closeLogOnClick = false }) => {
    let msg
    switch(mode) {
        case 'success':
            msg =  "<a role='button' class='alert-block green-alert'> " +
                "<div class='alert-block-icon'><i class='icon-checkmark-circle'></i></div>" +
                "<div class='alert-block-text'>" +
                "<div class='alert-block-value'>"+message+"</div>"+
                "</div>"+
                "<div class='alert-block-close'><i class='icon-alert-close'></i></div>" +
                "</a> "
            break
        case 'error':
            msg = "<a role='button' class='alert-block red-alert'> " +
                "<div class='alert-block-icon'></div>" +
                "<div class='alert-block-text'>" +
                "<div class='alert-block-value'>"+message+"</div>"+
                "</div>"+
                "<div class='alert-block-close'><i class='icon-alert-close'></i></div>" +
                "</a> "
            break
        case 'warning':
            msg = "<a role='button' class='alert-block orange-alert'> " +
                "<div class='alert-block-icon'></div>" +
                "<div class='alert-block-text'>" +
                "<div class='alert-block-value'>"+message+"</div>"+
                "</div>"+
                "<div class='alert-block-close'><i class='icon-alert-close'></i></div>" +
                "</a> "
            break
        case 'message':
            msg = "<a role='button' class='alert-block blue-alert'> " +
                "<div class='alert-block-icon'></div>" +
                "<div class='alert-block-text'>" +
                "<div class='alert-block-value'>"+message+"</div>"+
                "</div>"+
                "<div class='alert-block-close'><i class='icon-alert-close'></i></div>" +
                "</a> "
            break

        default:
            msg = "<a role='button' class='alert-block blue-alert'> " +
                "<div class='alert-block-icon'></div>" +
                "<div class='alert-block-text'>" +
                "<div class='alert-block-value'>"+message+"</div>"+
                "</div>"+
                "<div class='alert-block-close'><i class='icon-alert-close'></i></div>" +
                "</a> "
    }

    alertify.logPosition("top right");
    alertify.maxLogItems(1);
    if (!closeLogOnClick) {
        alertify.delay(delay);
        alertify.log(msg);
    }else {
        alertify.logPosition('top right').delay(0).closeLogOnClick(true).maxLogItems(2)
        alertify.log(msg, "", 0);
    }
}

import {regionConfigValue} from "./regionConfigValue";

export const getCookie = (name) => {
    var matches = document.cookie.match(new RegExp(
        // eslint-disable-next-line no-useless-escape
        "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
    ));
    return matches ? decodeURIComponent(matches[1]) : undefined;
}

export const deleteCookie = (name) => {
    const { COOKIE_DOMAIN } = regionConfigValue();
    document.cookie = `${name}= ; domain=${COOKIE_DOMAIN}; path=/; expires = Thu, 01 Jan 1970 00:00:00 GMT`;
}

export const setCookieForever = (name, value, domain = '.mos.ru') => {
    const { COOKIE_DOMAIN } = regionConfigValue();
    document.cookie = `${name}=${value}; domain=${COOKIE_DOMAIN}; path=/; expires = Thu, 01 Jan 2070 00:00:00 GMT`;
}

export const setCookie = (name, value) => {
    let date = new Date(new Date().getTime() + 60 * 1000 * 10);
    const { COOKIE_DOMAIN } = regionConfigValue();
    document.cookie = `${name}=${value}; domain=${COOKIE_DOMAIN}; path=/; expires=${new Date(
        date.getTime() * 3600).toUTCString()}`;
}

export const deleteAllCookies = () => {
    var cookies = document.cookie.split(";");

    for (var i = 0; i < cookies.length; i++) {
        var cookie = cookies[i];
        var eqPos = cookie.indexOf("=");
        var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
        if (name !== 'aupd_device_id') {
            document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
        }
    }
}

export const setCookieDay = (name, value, days) => {
    if(days){
        var d = new Date();
        d.setTime(d.getTime()+(days*24*60*60*1000));
        var expires = "; expires="+d.toGMTString();
    }
    else var expires = "";
    const { COOKIE_DOMAIN } = regionConfigValue();
    document.cookie = name+"="+value+expires+"; path=/;" + `domain=${COOKIE_DOMAIN};`;
}

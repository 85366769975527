import {
    TABLE_GET_SUCCESS,
    GET_USERS_DATA,
    GET_ROLES_DATA,
    GET_ROLES_SUCCESS,
} from 'constants/index';

export const tableGetSuccess = (payload) => ({
    type: TABLE_GET_SUCCESS, payload
});

export const getUsers = () => ({
    type: GET_USERS_DATA
})

export const getRoles = () => ({
    type: GET_ROLES_DATA
})

export const rolesGetSuccess = (payload) => ({
    type: GET_ROLES_SUCCESS, payload
})
import { GET_DEVICES_SUCCESS } from '../../constants'

export default (state = { }, action) => {
    switch(action.type){
        case GET_DEVICES_SUCCESS:
            return action.payload;

        default:
            return state;
    }
}
import { GET_CONTACTS_USER_SUCCESS } from '../../constants'

export default (state = { }, action) => {
    switch(action.type){
        case GET_CONTACTS_USER_SUCCESS:
            return action.payload;

        default:
            return state;
    }
}
import { lazy } from 'react';
const Home = lazy(() => import('../containers/Home'));
const Choice = lazy(() => import('../containers/Choice/choiceWrapper'));
const CallbackNew = lazy(() => import('../containers/Callback'));
const Mos = lazy(() => import('../containers/Choice/Mos'));
const Logout = lazy(() => import('../containers/Logout'));
const Activation = lazy(() => import('../containers/Activation'));
const WifiAuth = lazy(() => import('../containers/Wifi/auth'));
const ComponentWorks = lazy(() => import('../containers/504'));
const Redirect = lazy(() => import('../containers/Redirect'));

const Region50 = lazy(() => import('../containers/50'));
const Region = lazy(() => import('../containers/Region'));
const RegionNotFound = lazy(() => import('../containers/Region/RegionNotFound'));

const LoginContainer = lazy(() => import('../containers/Login'));
const Password = lazy(() => import('../containers/Password'));

export const routes = [
    {
        path: "/",
        exact: true,
        component: Home
    },
    {
        path: "/choice/mos",
        exact: true,
        private: true,
        component: Mos
    },
    {
        path: "/choice",
        exact: true,
        private: true,
        component: Choice
    },
    {
        path: "/choice/:id",
        exact: true,
        private: true,
        component: Choice
    },
    {
        path: "/auth/callback",
        exact: true,
        component: CallbackNew
    },
    {
        path: "/auth/logout",
        exact: true,
        component: Logout
    },
    {
        path: "/activation",
        exact: true,
        private: true,
        component: Activation
    },
    {
        path: "/password/set",
        exact: true,
        component: Password
    },
    {
        path: "/wifi",
        exact: true,
        component: WifiAuth
    },
    {
        path: "/redirect",
        exact: true,
        component: Redirect
    },
    /**
     * Region START esia
     */
    {
        path: "/esia",
        exact: true,
        component: Region
    },
    {
        path: "/esia/:regionId",
        exact: true,
        component: Region
    },
    /**
     * Статичные старые регионы - новых не добавлять!
     * Все новые регионы добавляются динамически, по роуту /esia/:regionId
     */
    {
        path: "/50",
        exact: true,
        component: Region50
    },
    {
        path: "/16",
        exact: true,
        component: Region
    },
    {
        path: "/116",
        exact: true,
        component: Region
    },
    {
        path: "/40",
        exact: true,
        component: Region
    },
    {
        path: "/05",
        exact: true,
        component: Region
    },
    {
        path: "/72",
        exact: true,
        component: Region
    },
    {
        path: "/105",
        exact: true,
        component: Region
    },
    /**
     * Region END
     */


    {
        path: "/sign_in",
        exact: true,
        component: LoginContainer
    },
    {
        path: "/504",
        exact: true,
        component: ComponentWorks
    },
    {
        path: "/404",
        exact: true,
        component: RegionNotFound
    },
]

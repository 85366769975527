import { put, takeLatest, all, call } from 'redux-saga/effects';
import { GET_PIN_REQUEST, UPDATE_PIN_REQUEST } from 'constants/index';
import { getPinSuccess, updatePinSuccess } from 'actions/pin'
import API from 'API'
import { alert } from 'utils/alertify'

function* getPinRequest() {
    try {
        const { pin, error } = yield call(API.getPin);
        if (error) {
            alert({mode: 'error', message: error})
        }else {
            yield put(getPinSuccess(pin));
        }
    }catch (e) {
        console.log(e)
    }
}

function* updatePinRequest() {
    try {
        const { pin, error } = yield call(API.updatePin);
        if (error) {
            alert({mode: 'error', message: error})
        }else {
            yield put(updatePinSuccess(pin));
        }
    }catch (e) {
        console.log(e)
    }
}

function* Saga() {
    yield all([
        takeLatest(GET_PIN_REQUEST, getPinRequest),
        takeLatest(UPDATE_PIN_REQUEST, updatePinRequest),
    ]);
}

export default Saga;

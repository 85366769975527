import {GET_CHILDREN_SUCCESS, GET_LOGIN_SUCCESS} from 'constants/index'

export default (state = [], action) => {
    switch(action.type){
        case GET_CHILDREN_SUCCESS:
            return action.payload;

        case GET_LOGIN_SUCCESS:
            state.login = action.payload
            return state;
        default:
            return state;
    }
}
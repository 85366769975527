import {
    GET_DEVICES_REQUEST,
    GET_DEVICES_SUCCESS,
    DELETE_DEVICES_REQUEST,
    ADD_DEVICE_REQUEST,
    DEACTIVATE_NOTIFICATION,
    EDIT_DEVICE_REQUEST,
}
from 'constants/index';

export const getDevicesRequest = (payload) => ({
    type: GET_DEVICES_REQUEST, payload
})

export const getDevicesSuccess = (payload) => ({
    type: GET_DEVICES_SUCCESS, payload
})

export const deleteDivices = (payload) => ({
    type: DELETE_DEVICES_REQUEST, payload
})

export const addDeviceRequest = (payload) => ({
    type: ADD_DEVICE_REQUEST, payload
})

export const editDeviceRequest = (payload) => ({
    type: EDIT_DEVICE_REQUEST, payload
})

export const deactivateNotifications = (payload) => ({
    type: DEACTIVATE_NOTIFICATION, payload
})
